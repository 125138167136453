const env = {
  phrase: {
    token: 'c4fd1f41883f925e56a3f3fbf37b874f28029194710bcd7d586a3e78add88bd7',
    projectId: 'b95909fab887a30c994c7449151ce557',
    localeIds: {
      en: 'd875a7f7f4c7e7688c1cb1961339127c',
      ca: 'e07cb9c73a86265136526313a52fa170',
      de: 'a3fc59ae4ccb09fb148a88f9eb1be116',
      el: 'ee416340a121e40a672491d91e471fb7',
      es: '1f1a28c7b4f935375b52e3d8f1c2959c',
      fr: 'e3152f3878223264a5c3e40f639b0064',
      it: 'e48ea86f687ab60baf49b81b7514f3b8',
      ja: '9897cd3d69616a310554f86b8be4ac46',
      nl: '2ff0385cd28e860981e0f0f3b18052c5',
      pl: 'ba454c595fdbc0d6267ed37df94440bb',
      pt: 'a5aeafdc2da7e9d7d756b8a96ac9e13d',
    },
  },
  socketUrl: 'wss://737mspryph.execute-api.eu-west-3.amazonaws.com/prod',
  profilePicture: {
    ratio: 10 / 16,
  },
};

// const prod = {
//   ...env,
//   apiUrl: "/api",
//   monetico: {
//     baseUrl: "https://p.monetico-services.com",
//   },
// };

const prod = {
  ...env,
  apiUrl: 'https://api.dev.thegoodweather.com/api',
  socketUrl: 'wss://bgsqpk824b.execute-api.eu-west-3.amazonaws.com/staging',
  monetico: {
    baseUrl: 'https://p.monetico-services.com',
  },
  castle: {
    publishableKey: 'pk_agshj9Hrd4Wyi11phVhUMp5Eu6PAqfdW',
  },
};

const staging = {
  ...env,
  apiUrl: 'https://api.dev.thegoodweather.com/api',
  socketUrl: 'wss://bgsqpk824b.execute-api.eu-west-3.amazonaws.com/dev',
  monetico: {
    baseUrl: 'https://p.monetico-services.com',
  },
  castle: {
    publishableKey: 'pk_agshj9Hrd4Wyi11phVhUMp5Eu6PAqfdW',
  },
};

const dev = {
  ...env,
  apiUrl: 'http://localhost:3000/api',
  socketUrl: 'wss://bgsqpk824b.execute-api.eu-west-3.amazonaws.com/dev',
  monetico: {
    baseUrl: 'https://p.monetico-services.com/test',
  },
  castle: {
    publishableKey: 'pk_agshj9Hrd4Wyi11phVhUMp5Eu6PAqfdW',
  },
};

export const environment =
  process.env.NODE_ENV === 'development'
    ? dev
    : window.location.host === 'd13s4g3q9ouuo3.cloudfront.net'
    ? staging
    : prod;
// export const environment =
//     process.env.ENV === "PROD"
//         ? prod
//         : location && location.hostname.includes("staging")
//         ? staging
//         : dev;
