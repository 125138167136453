import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { NotifProvider } from "./context/notif-context";
import Cookies from "universal-cookie";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://fd93024befd94ae6913eeea44250ffe3@o4504717532004352.ingest.sentry.io/4504717534625792",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("token");
const showModal = urlParams.get("showModal");
if (token && showModal !== "resetPassword") {
  const cookies = new Cookies();
  cookies.set("token", token, {
    domain: ".bearwww.com",
    path: "/",
    secure: true,
  });
  localStorage.setItem("token", token);
  const language = urlParams.get("language");
  if (language) {
    localStorage.setItem("language", language);
  }

  window.location.href = window.location.origin;
}

ReactDOM.render(
  <React.StrictMode>
    <NotifProvider>
      <App />
    </NotifProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
